* {
    box-sizing: border-box;
}

body {
    margin: 0;
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    line-height: 1.6;
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f6f4f48a;

}

.App {
    width: clamp(300px, 80%, 1000px);
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

main {
    width: 100%;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th, td {
    text-align: left;
    padding: 8px;
}

tr:nth-child(even){
    background-color: #f2f2f2
}

/* Table container */
.table-container {
    width: 100%;
    max-width: 100vw;
    overflow-x: scroll;
}

/* Table cells */
span.table-cell {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}

span.table-cell.table-change-increase {
    color: #12a812;
}

span.table-cell.table-change-decrease {
    color: #e11616;
}

span.table-cell.table-change-none {
    color: rgb(67, 67, 67);
}

span.table-cell.table-change-na {
    color: #888!important;
    font-style: italic;
}

/* Loading */
td.loading {
    text-align: center;
    padding: 20px;
    font-weight: bold;
}

td.loading::after {
    content: "";
    animation: ellipsis 1800ms infinite;
}

/* Error */
td.error {
    text-align: center;
    padding: 20px;
    font-weight: bold;
    color: #e11616;
}

@keyframes ellipsis {
    0% {
        content: "";
    }

    33% {
        content: ".";
    }

    66% {
        content: "..";
    }

    80% {
        content: "...";
    }
}

/* Article Styling */
.article-title {
    font-weight: 800;
    letter-spacing: -.05rem;
    margin: 1rem 0;
    font-size: 2rem;
    line-height: 1.3;
}

.article-date {
    color:rgb(67, 67, 67);
}

.article-banner {
    width: 100%;
    padding-bottom: 50%;
    overflow: hidden;
    position: relative;
}

.article-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}

/* Sorting */
.cursor-pointer {
    cursor: pointer;
}

.select-none {
    user-select: none;
}

/* Original code by Markit Digital (mod'd), Codepen.io */
th.column-sortable::after {
    content: '';
    display: inline-block;
    padding-right: 1px;
    padding-left: 0;
    margin-left: 0.5em;
    transform: translateY(-50%);
}

th.column-sortable:not(.column-desc):not(.column-asec):hover::after,
th.column-asec::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(17, 17, 17, 0.642);
}

th.column-desc::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #111;
}

th.column-asec::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #111;
}
/* Markit Digital code ends here */

/* Footer */
footer {
    text-align: center;
    padding: 20px;
    color: #888;
}

footer span {
    margin: 0.5em;
}

footer::before {
    content: "";
    display: block;
    width: 100;
    background-color: #888;
}